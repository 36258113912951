import React from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Link} from "react-router-dom";
import Typewriter from "typewriter-effect";
import {introdata, meta} from "../../content_option";
import "./style.css";

export const Home = () => {
  return (
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <link
            rel="canonical"
            href="https://www.agametis.de/filemaker-programmierer-muenchen"
          />
        </Helmet>
        <div className="intro_sec d-block d-lg-flex align-items-center ">
          <div
            className="h_bg-image order-1 order-lg-2 h-100 "
            style={{backgroundImage: `url(${introdata.your_img_url})`}}
          ></div>
          {/* <div
            className="h_bg-image order-1 order-lg-2 h-100 d-flex justify-content-end"
          ><video src="./assets/agametis_2.mp4" muted loop autoPlay></video></div> */}
          <div className="text order-2 order-lg-1 h-100 d-lg-flex justify-content-center">
            <div className="align-self-center">
              <div className="intro mx-auto">
                <h2 className="mb-1x">{introdata.title}</h2>
                <h1 className="mb-1x">
                  {/* <Typewriter
                    options={{
                      strings: [
                        introdata.animated.first,
                        introdata.animated.second,
                        introdata.animated.third,
                        introdata.animated.fourth,
                      ],
                      autoStart: true,
                      loop: true,
                      deleteSpeed: "natural",
                      pauseFor: 1000,
                      delay: "natural",
                    }}
                  /> */}
                  <Typewriter
                    options={{
                      loop: true,
                      deleteSpeed: "natural",
                      delay: "natural",
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .pauseFor(700)
                        .typeString(introdata.animated.first)
                        .pauseFor(100)
                        .changeDeleteSpeed(20)
                        .deleteChars(19)
                        .typeString(introdata.animated.second)
                        .pauseFor(100)
                        .deleteChars(19)
                        .typeString(introdata.animated.third)
                        .pauseFor(50)
                        .deleteAll()
                        .typeString(introdata.animated.fourth)
                        .pauseFor(80)
                        .deleteChars(9)
                        .typeString(introdata.animated.fifth)
                        .pauseFor(2500)
                        .start();
                    }}
                  />
                </h1>
                <p className="mb-1x">{introdata.description}</p>
                <div className="intro_btn-action pb-5">
                  <Link to="/portfolio" className="text_2">
                    <div id="button_p" className="ac_btn btn ">
                      Portfolio
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                  </Link>
                  <Link to="/kontakt">
                    <div id="button_h" className="ac_btn btn">
                      Kontakt
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};
