import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {meta} from "../../content_option";
import "./style.css";

export const Aktivitaeten = () => {
  return (
    <HelmetProvider>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.title} | Aktivitäten</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mt-4 mb-4">Aktivitäten</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="12" className="mb-5 mt-0">
            <p>
              Im Laufe der Jahre habe ich an zahlreichen Veranstaltungen über
              FileMaker und Webentwicklung teilgenommen. Auf vielen davon habe
              ich auch Vorträge zu Themen rund um FileMaker Entwicklung sowie
              Integration gehalten. Hier ist ein kleiner Überlick mit Verweisen
              auf vorhandens Vortrags- und Demomaterial.
            </p>
            <hr className="t_border my-4 ml-0 text-left" />

            {/* == 2024 == */}
            <h5 className="color_sec py-2 mb-1">2024</h5>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://github.com/agametis/fmk2024_demos/tree/main/01_Datenaustausch"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "Datenaustausch zwischen FileMaker und WebViewer"
                </a>{" "}
                mit Demodateien auf{" "}
                <a
                  href="https://github.com/agametis/fmk2024_demos/"
                  target="_blank"
                  rel="noreferrer"
                  title="Demos"
                >
                  Github
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://github.com/agametis/fmk2024_demos/tree/main/02_Anonyme_Funktionen"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "Anonyme JavaScript Funktionen in FileMaker einsetzen"
                </a>{" "}
                mit Demodateien auf{" "}
                <a
                  href="https://github.com/agametis/fmk2024_demos/"
                  target="_blank"
                  rel="noreferrer"
                  title="Demos"
                >
                  Github
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.dotfmp.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  dotfmp
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://github.com/agametis/fm-react-demo"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "Bidirectional communication between FileMaker and React in
                  WebViewer"
                </a>{" "}
                mit Demodateien auf{" "}
                <a
                  href="https://github.com/agametis/fm-react-demo"
                  target="_blank"
                  rel="noreferrer"
                  title="Demos"
                >
                  Github
                </a>
              </li>
            </ul>

            {/* == 2023 == */}
            <h5 className="color_sec py-2 mb-1">2023</h5>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://github.com/agametis/fmk2023_demos/tree/main/01_Gantt_Dateien"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "JavaScript Track 2: Gantt-Chart"
                </a>{" "}
                mit Demodateien auf{" "}
                <a
                  href="https://github.com/agametis/fmk2023_demos/"
                  target="_blank"
                  rel="noreferrer"
                  title="Demos"
                >
                  Github
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://github.com/agametis/fmk2023_demos/tree/main/02_Kalender_Dateien"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "JavaScript Track 4: Kalender"
                </a>{" "}
                mit Demodateien auf{" "}
                <a
                  href="https://github.com/agametis/fmk2023_demos/"
                  target="_blank"
                  rel="noreferrer"
                  title="Demos"
                >
                  Github
                </a>
              </li>
            </ul>

            {/* == 2022 == */}
            <h5 className="color_sec py-2 mb-1">2022</h5>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://www.slideshare.net/fmkonferenz/fmk2022-filemaker-und-javascript-von-adam-augustin"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "FileMaker und Javascript"
                </a>{" "}
                mit Demodateien auf{" "}
                <a
                  href="https://github.com/agametis/fmk2022_demos/"
                  target="_blank"
                  rel="noreferrer"
                  title="Demos"
                >
                  Github
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://www.slideshare.net/fmkonferenz/fmk2022-neue-programmiertechniken-von-adam-augusting"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "Überarbeitete Programmiertechniken"
                </a>{" "}
                mit Demodateien auf{" "}
                <a
                  href="https://github.com/agametis/fmk2022_demos/"
                  target="_blank"
                  rel="noreferrer"
                  title="Demos"
                >
                  Github
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der amerikanischen FileMaker
                Konferenz{" "}
                <a
                  href="https://autoenter.live/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  AutoEnter Live!
                </a>{" "}
                (online)
              </li>
            </ul>
            <ul>
              <li>
                <strong>Teilnahme</strong> an{" "}
                <a
                  href="https://www.react-native.eu/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  React Native EU
                </a>{" "}
                (online)
              </li>
            </ul>

            {/* == 2021 == */}
            <h5 className="color_sec py-2 mb-1">2021</h5>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der paneuropäischen FileMaker
                Konferenz{" "}
                <a
                  href="https://www.dotfmp.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  dotfmp
                </a>{" "}
                (online)
              </li>
            </ul>
            <ul>
              <li>
                <strong>Teilnahme</strong> an{" "}
                <a
                  href="https://vueconf.us"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  VueConf US
                </a>{" "}
                (online)
              </li>
            </ul>

            {/* == 2020 == */}
            <h5 className="color_sec py-2 mb-1">2020</h5>
            <ul>
              <li>
                <strong>Konferenz: </strong>
                <a
                  href="https://www.dotfmp.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  dotfmp
                </a>{" "}
                (online)
              </li>
              <li>
                <strong>Vortrag: </strong>"LogViewer for FileMaker Server: The
                Missing One"
              </li>
            </ul>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der amerikanischen FileMaker
                Developer Conference{" "}
                <a
                  href="https://community.claris.com/en/s/engage"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  Claris Engage 2020
                </a>{" "}
                (online)
              </li>
            </ul>

            {/* == 2019 == */}
            <h5 className="color_sec py-2 mb-1">2019</h5>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://de.slideshare.net/fmkonferenz/fmk2019-adamaugustinagametisfile-makerdapimitnodejsvortrag"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "FileMaker Data API mit Node.js nutzen"
                </a>
              </li>
              <li>
                <strong>Workshop:</strong>{" "}
                <a
                  href="https://www.slideshare.net/fmkonferenz/fmk2019-adamaugustinagametisfile-makerdapimitnodejsworkshop"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "FileMaker Data API mit Node.js nutzen"
                </a>
              </li>
              <li>
                <strong>LogViewer:</strong>{" "}
                <a
                  href="https://www.github.com/agametis"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  FMS LogViewer Sourcecode auf github
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.dotfmp.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  dotfmp
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong> "FileMaker Server with GraphQL
                Interface"
              </li>
            </ul>

            {/* == 2018 == */}
            <h5 className="color_sec py-2 mb-1">2018</h5>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.dotfmp.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  dotfmp
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong> "GraphQL: Beyond REST"
              </li>
            </ul>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der deutschsprachigen FileMaker
                Konferenz{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>{" "}
                in Malbun
              </li>
            </ul>

            {/* == 2017 == */}
            <h5 className="color_sec py-2 mb-1">2017</h5>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>
              </li>
              <li>
                <strong>Vortrag und Workshop:</strong>{" "}
                <a
                  href="https://www.slideshare.net/fmkonferenz/fmk2017-das-filemaker-selectorconnector-modell-wie-kann-ich-es-sinnvoll-einsetzen-by-adam-augustin"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "Selector-Connector: Wie kann ich es sinnvoll einsetzen?"
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der amerikanischen FileMaker
                Developer Conference{" "}
                <a
                  href="https://www.filemaker.com/learning/devcon/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  DevCon
                </a>{" "}
                in Phoenix, AZ
              </li>
            </ul>

            {/* == 2016 == */}
            <h5 className="color_sec py-2 mb-1">2016</h5>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://www.slideshare.net/fmkonferenz/fmk2016-adam-augustin-vom-suchen-und-finden-in-filemaker"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "Vom Suchen und Finden"
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.dotfmp.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  dotfmp
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong> "Collecting Pictures with iPhone and
                Syncing with FileMaker Server"
              </li>
            </ul>

            {/* == 2015 == */}
            <h5 className="color_sec py-2 mb-1">2015</h5>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://de.slideshare.net/fmkonferenz/fmk2015-entwicklung-von-modernen-benutzeroberflchenmitfilemakerpro"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "Entwicklung von modernen Benutzeroberflächen mit FileMaker
                  Pro"
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://de.slideshare.net/fmkonferenz/fmk2015-moderne-benutzeroberflchen-am-beispiel-einer-filemakerlsung-by-adam-augustin-53918295"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "Moderne Benutzeroberflächen am Beispiel einer FileMaker
                  Lösung"
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://de.slideshare.net/fmkonferenz/fmk2015-das-anker-bojen-modell-um-selector-connector-erweitern-by-adam-augustin-und-volker-krambrich"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "Das Anker Bojen Modell um Selector Connector erweitern"
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <strong>Konferenz: </strong>
                <a
                  href="https://www.dotfmp.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  dotfmp
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong> "What about Selector Connector?"
              </li>
            </ul>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der amerikanischen FileMaker
                Developer Conference{" "}
                <a
                  href="https://www.filemaker.com/learning/devcon/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  DevCon
                </a>{" "}
                in Las Vegas, NV
              </li>
            </ul>

            {/* == 2014 == */}
            <h5 className="color_sec py-2 mb-1">2014</h5>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der deutschsprachigen FileMaker
                Konferenz{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>{" "}
                in Winterthur
              </li>
            </ul>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der amerikanischen FileMaker
                Developer Conference{" "}
                <a
                  href="https://www.filemaker.com/learning/devcon/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  {" "}
                  DevCon
                </a>
                in San Antonio, TX
              </li>
            </ul>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der paneuropäischen FileMaker
                Konferenz{" "}
                <a
                  href="https://www.dotfmp.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  dotfmp
                </a>{" "}
                in Berlin
              </li>
            </ul>

            {/* == 2013 == */}
            <h5 className="color_sec py-2 mb-1">2013</h5>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der deutschsprachigen FileMaker
                Konferenz{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>{" "}
                in Salzburg
              </li>
            </ul>

            {/* == 2012 == */}
            <h5 className="color_sec py-2 mb-1">2012</h5>
            <ul>
              <li>
                <strong>Konferenz:</strong>{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>
              </li>
              <li>
                <strong>Vortrag:</strong>{" "}
                <a
                  href="https://de.slideshare.net/fmkonferenz/fmk2012-augustin-vortragperfektedruckausgabemitlatexundfilemaker"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "Perfekte Druckausgabe mit LaTeX und FileMaker"
                </a>
              </li>
              <li>
                <strong>Workshop:</strong>{" "}
                <a
                  href="https://de.slideshare.net/fmkonferenz/fmk2012-augustin-workshopeinbindungvonlatexineinefilemakerloesung"
                  target="_blank"
                  rel="noreferrer"
                  title="Slides"
                >
                  "Einbindung von LaTeX in eine FileMaker Lösung"
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der amerikanischen FileMaker
                Developer Conference{" "}
                <a
                  href="https://www.filemaker.com/learning/devcon/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  DevCon
                </a>{" "}
                in Miami, FL
              </li>
            </ul>

            {/* == 2011 == */}
            <h5 className="color_sec py-2 mb-1">2011</h5>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der deutschsprachigen FileMaker
                Konferenz{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>{" "}
                in Hamburg
              </li>
            </ul>

            {/* == 2010 == */}
            <h5 className="color_sec py-2 mb-1">2010</h5>
            <ul>
              <li>
                <strong>Teilnahme</strong> an der deutschsprachigen FileMaker
                Konferenz{" "}
                <a
                  href="https://www.filemaker-konferenz.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage"
                >
                  FMK
                </a>{" "}
                in Zürich
              </li>
            </ul>
          </Col>
          <Col className="mt-5"></Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
