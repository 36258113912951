const logotext = "agametis";
const meta = {
  firma: "agametis - Dr. Adam G. Augustin",
  title: "agametis - Dr. Adam G. Augustin",
  description: "Claris FileMaker Berater und Entwickler aus dem Raum München",
  portfoliodescription: "Hier bekommen Sie einen Eindruck von meiner Arbeit.",
  portfoliodescription_mobile: "(Screenshots am besten am Desktop anschauen)",
};

const introdata = {
  title: "Werden Sie produktiver",
  animated: {
    first: "durch strukturierte Daten",
    second: "definierte Prozesse",
    third: "Software, die Sie unterstützt",
    fourth: "und gewinnen Sie so wertvolle",
    fifth:
      "<span style='color: var(--secondary-color);'>Ihre wertvolle Zeit</span> zurück.",
  },
  description:
    "Claris FileMaker Beratung und Entwicklung in München und Umgebung.",
  your_img_url: "./assets/img/agametis_bg_1.jpg",
};

const dataabout = {
  title: "Dr. Adam G. Augustin",
  adam_img_url: "./assets/img/adam_augustin.jpg",
  aboutme_1:
    "Seit 2010 entwickle und betreue ich Projekte mit der modernen und flexiblen Claris FileMaker® Plattform. Ich bin Teil eines Netzwerkes von erfahrenen FileMaker Entwicklern im Raum München, die angefangen bei der Beratung, Planung, Organisation und Entwicklung bis hin zur Integration einer FileMaker Lösung den gesamten Entwicklungsprozess abdecken.",
  aboutme_2:
    "Ebenso unterstützen wir Kunden, die bereits eigene FileMaker Projekte einsetzen und eine Betreuung sowie Weiterentwicklung ihrer Bestandslösung wünschen.",
};

const dataerfahrung = {
  title: "Erfahrung",
  erfahrung_1:
    "Profitieren Sie von meiner über 10 jährigen Erfahrung mit der flexiblen Claris FileMaker® Plattform und dem schnellen und zuverlässigen Support.",
  erfahrung_2:
    "Die Claris FileMaker® Plattform ist nur ein Tool von vielen, mit dem ich die Lösungen meiner Kunden entwickle. ReactJS sowie React Native sind zwei weitere Werkzeuge, die bei meiner täglich Arbeit eingesetzt werden. Durch die vielfältigen Möglichkeiten der Claris FileMaker® Plattform, die Datenbanklösungen in heterogene Softwareumgebungen zu integrieren, sind meine umfangreichen Kenntnisse in IT-Infrastruktur und Fremdsoftware unerlässlich, für Sie, robuste und gut integrierte Software zu entwicklen.",
};

const dataphilosophie = {
  title: "Philosophie",
  text_1:
    "Eine intuitive Benutzeroberfläche gepaart mit einer prozessorientierten Programmstruktur ist unsere wichtigste Produktzielsetzung, um die Organisation in Ihrem Unternehmen so effizient wie möglich zu gestalten. Kundennutzen, Produktqualität sowie Kundenservice stehen stets im Fokus der Entwicklung.",
  text_2:
    "Denn sind wir mal ehrlich. Was nutzt Ihnen eine umständlich zu bedienende Datenbank, die nicht mehr zeitgemäß aussieht und vor allem Ihre Anforderungen nicht erfüllt? Wir haben es uns zum Ziel gesetzt, kundenspezifische Datenbanksysteme zu entwickeln, die Ihnen von Nutzen sind, die Ihnen die täglichen Arbeitsabläufe erleichtern und zudem auch attraktiv sind",
  text_3:
    "Von uns bekommen Sie ein Produkt, welches zu Ihnen passt. Zufriedenheit und Spaß bei der Nutzung ist die Basis für eine erfolgreiche und langfristige Zusammenarbeit.",
};

const dataUebrigens = {
  title: "... und übrigens",
  text: "Durch den Einsatz einer datenbankgestützen Lösung steigern Sie zwangsläufig bei ihrer täglichen Arbeit die Produktivität und Effizienz. Damit ergibt sich der return of investment quasi von selbst.",
};

const services = [
  {
    title: "Beratung",
    description:
      "Wir beraten Sie intensiv, um eine optimale Datenbanklösung mit Ihnen gemeinsam zu entwickeln.",
  },
  {
    title: "Entwicklung",
    description:
      "Wir entwickeln benutzerfreundliche Speziallösungen für Ihre Unternehmensorganisation.",
  },
  {
    title: "Betreuung",
    description:
      "Wir betreuen Sie auch nach der Projektfertigstellung persönlich und zuverlässig.",
  },
];

const dataportfolio = [
  {
    title: "Immobilienbewertung",
    year: "2010 bis heute",
    img: "./assets/img/portfolio/agametis_immobilienbewertung_0.png",
    desctiption:
      "Angebot, Auftrag, Gutachtenerstellung und Rechnung - der gesamte Prozess in einer Lösung.",
    images: [
      {
        original: "./assets/img/portfolio/agametis_immobilienbewertung_1.png",
        thumbnail: "./assets/img/portfolio/agametis_immobilienbewertung_1.png",
        description: "Übersicht der Aufträge",
      },
      {
        original: "./assets/img/portfolio/agametis_immobilienbewertung_2.png",
        thumbnail: "./assets/img/portfolio/agametis_immobilienbewertung_2.png",
        description: "Datenerfassung: Basisdefinitionen",
      },
      {
        original: "./assets/img/portfolio/agametis_immobilienbewertung_3.png",
        thumbnail: "./assets/img/portfolio/agametis_immobilienbewertung_3.png",
        description: "Datenerfassung: Überischt der Mieter",
      },
      {
        original: "./assets/img/portfolio/agametis_immobilienbewertung_4.png",
        thumbnail: "./assets/img/portfolio/agametis_immobilienbewertung_4.png",
        description: "Ermiitlung des Sachwertes (Ausschnitt)",
      },
      {
        original: "./assets/img/portfolio/agametis_immobilienbewertung_5.png",
        thumbnail: "./assets/img/portfolio/agametis_immobilienbewertung_5.png",
        description:
          "Bewertungsergebnisse: Übersicht mit Import-/Exportmöglichkeiten in verschiedenen Formaten",
      },
      {
        original: "./assets/img/portfolio/agametis_immobilienbewertung_6.png",
        thumbnail: "./assets/img/portfolio/agametis_immobilienbewertung_6.png",
        description: "Gutachtentext: Erstellung und Organisation der Module",
      },
      {
        original: "./assets/img/portfolio/agametis_immobilienbewertung_7.png",
        thumbnail: "./assets/img/portfolio/agametis_immobilienbewertung_7.png",
        description:
          "Backend: Definition und Organisation von Prozessschritten",
      },
    ],
  },
  {
    title: "Wirtschaftsmediation",
    year: "2012",
    img: "./assets/img/portfolio/agametis_mediation_0.png",
    desctiption:
      "Organisation von Akten und Vorgängen bei einer Wirtschaftsmediation",
    images: [
      {
        original: "./assets/img/portfolio/agametis_mediation_1.png",
        thumbnail: "./assets/img/portfolio/agametis_mediation_1.png",
        description: "Aktenübericht",
      },
      {
        original: "./assets/img/portfolio/agametis_mediation_2.png",
        thumbnail: "./assets/img/portfolio/agametis_mediation_2.png",
        description: "Details zu einer Akte und deren Vorgängen",
      },
      {
        original: "./assets/img/portfolio/agametis_mediation_3.png",
        thumbnail: "./assets/img/portfolio/agametis_mediation_3.png",
        description: "Erfassung von Adressdaten und Etikettendruck",
      },
      {
        original: "./assets/img/portfolio/agametis_mediation_4.png",
        thumbnail: "./assets/img/portfolio/agametis_mediation_4.png",
        description: "Übersicht und Erstellung von Rechnungen",
      },
      {
        original: "./assets/img/portfolio/agametis_mediation_5.png",
        thumbnail: "./assets/img/portfolio/agametis_mediation_5.png",
        description: "Einstellungen und Dokumentenvorlagen",
      },
    ],
  },
  {
    title: "Bibliothek",
    year: "2017",
    img: "./assets/img/portfolio/agametis_bibliothek_0.png",
    desctiption:
      "Organisation und Verschlagwortung von Dokumenten und Quellen für Immobilienbewertungen",
    images: [
      {
        original: "./assets/img/portfolio/agametis_bibliothek_1.png",
        thumbnail: "./assets/img/portfolio/agametis_bibliothek_1.png",
        description: "Übersicht über verfügbare Quellen",
      },
      {
        original: "./assets/img/portfolio/agametis_bibliothek_2.png",
        thumbnail: "./assets/img/portfolio/agametis_bibliothek_2.png",
        description: "Liste mit Quellen einer Kategorie",
      },
      {
        original: "./assets/img/portfolio/agametis_bibliothek_3.png",
        thumbnail: "./assets/img/portfolio/agametis_bibliothek_3.png",
        description:
          "Details einer Quelle mit der Möglichkeit zur Verschlagwortung und Lagezuordung",
      },
    ],
  },
  {
    title: "Auftragsverwaltung",
    year: "2015 bis heute",
    img: "./assets/img/portfolio/agametis_auftragserfassung_0.png",
    desctiption:
      "Auftragserfassung, Projektdokumentation und Arbeitszeitauswertung",
    images: [
      {
        original: "./assets/img/portfolio/agametis_auftragserfassung_1.png",
        thumbnail: "./assets/img/portfolio/agametis_auftragserfassung_1.png",
        description: "Startlayout",
      },
      {
        original: "./assets/img/portfolio/agametis_auftragserfassung_2.png",
        thumbnail: "./assets/img/portfolio/agametis_auftragserfassung_2.png",
        description: "Schnellerfassung eines Projektes",
      },
      {
        original: "./assets/img/portfolio/agametis_auftragserfassung_3.png",
        thumbnail: "./assets/img/portfolio/agametis_auftragserfassung_3.png",
        description: "FiBu-Auswertungen für den Steuerberater",
      },
    ],
  },
  {
    title: "Objektbegehung",
    year: "2021",
    img: "./assets/img/portfolio/agametis_objektbegehung_0.png",
    desctiption: "Erfassung von Objektdaten vor Ort mit FileMaker Go",
    images: [
      {
        original: "./assets/img/portfolio/agametis_objektbegehung_1.png",
        thumbnail: "./assets/img/portfolio/agametis_objektbegehung_1.png",
        description: "Auftragsliste in der lokalen Datenbank auf dem iPad",
      },
      {
        original: "./assets/img/portfolio/agametis_objektbegehung_2.png",
        thumbnail: "./assets/img/portfolio/agametis_objektbegehung_2.png",
        description: "Erfassung der Daten vor Ort",
      },
      {
        original: "./assets/img/portfolio/agametis_objektbegehung_3.png",
        thumbnail: "./assets/img/portfolio/agametis_objektbegehung_3.png",
        description:
          "Synchronisation der lokalen Daten mit der Hauptanwendung auf dem Server",
      },
    ],
  },
  {
    title: "agaFin",
    year: "2010 bis heute",
    img: "./assets/img/portfolio/agametis_agafin_0.png",
    desctiption:
      "InhouseCRM zum Verwalten von Kundendaten, Arbeitszeiten und Erstellung von Rechnungen",
    images: [
      {
        original: "./assets/img/portfolio/agametis_agafin_1.png",
        thumbnail: "./assets/img/portfolio/agametis_agafin_1.png",
        description: "Dashboard mit aktiven Kunden und aktuellen Rechnungen",
      },
      {
        original: "./assets/img/portfolio/agametis_agafin_2.png",
        thumbnail: "./assets/img/portfolio/agametis_agafin_2.png",
        description: "Erfassung der Arbeitszeit",
      },
      {
        original: "./assets/img/portfolio/agametis_agafin_3.png",
        thumbnail: "./assets/img/portfolio/agametis_agafin_3.png",
        description: "Erfassung der Adressdaten",
      },
      {
        original: "./assets/img/portfolio/agametis_agafin_4.png",
        thumbnail: "./assets/img/portfolio/agametis_agafin_4.png",
        description: "Übersicht der Ausgaben",
      },
    ],
  },
  {
    title: "Mailingsystem",
    year: "2022 bis heute",
    img: "./assets/img/portfolio/agametis_mailing_0.png",
    desctiption:
      "Adressverwaltung und Organisation von Mailings und Aussendungen",
    images: [
      {
        original: "./assets/img/portfolio/agametis_mailing_1.png",
        thumbnail: "./assets/img/portfolio/agametis_mailing_1.png",
        description: "Startlayout",
      },
      {
        original: "./assets/img/portfolio/agametis_mailing_2.png",
        thumbnail: "./assets/img/portfolio/agametis_mailing_2.png",
        description: "Adresserfassung mit Personendetails",
      },
      {
        original: "./assets/img/portfolio/agametis_mailing_5.png",
        thumbnail: "./assets/img/portfolio/agametis_mailing_5.png",
        description: "Listenansicht mit Personendaten",
      },
      {
        original: "./assets/img/portfolio/agametis_mailing_3.png",
        thumbnail: "./assets/img/portfolio/agametis_mailing_3.png",
        description: "Auswahl der Teilnehmer für eine Korrespondenz",
      },
      {
        original: "./assets/img/portfolio/agametis_mailing_4.png",
        thumbnail: "./assets/img/portfolio/agametis_mailing_4.png",
        description: "Test eines Mailings",
      },
    ],
  },
  {
    title: "Fotodokumentation (iOS/Android)",
    link: "https://photodocu.agametis.de",
    year: "2022 bis heute",
    img: "./assets/img/portfolio/agametis_photodocu_0.png",
    desctiption:
      "App zur Erfassung von Objektfotos mit einer nativen iOS bzw. Android App)",
    images: [
      {
        original: "./assets/img/portfolio/agametis_photodocu_1.png",
        thumbnail: "./assets/img/portfolio/agametis_photodocu_1.png",
        description: "Mobile App zum Erfassen von Objektfotos",
      },
      {
        original: "./assets/img/portfolio/agametis_photodocu_2.png",
        thumbnail: "./assets/img/portfolio/agametis_photodocu_2.png",
        description: "Objektübersicht mit Transfer der Daten über Data API",
      },
      {
        original: "./assets/img/portfolio/agametis_photodocu_3.png",
        thumbnail: "./assets/img/portfolio/agametis_photodocu_3.png",
        description: "Übersicht mit erfassten Fotos",
      },
      {
        original: "./assets/img/portfolio/agametis_photodocu_4.png",
        thumbnail: "./assets/img/portfolio/agametis_photodocu_4.png",
        description: "Einstellungen mit u.a. Zugangsdaten zum FileMaker Server",
      },
    ],
  },

  {
    title: "Kundenportal (WebDirect)",
    year: "2017",
    img: "./assets/img/portfolio/agametis_kundenportal_0.png",
    desctiption:
      "Onlineabfrage des Aftragsstatus und Übersicht des Kundenportfolios",
    images: [
      {
        original: "./assets/img/portfolio/agametis_kundenportal_0.png",
        thumbnail: "./assets/img/portfolio/agametis_kundenportal_0.png",
        description: "Login im Browser",
      },
      {
        original: "./assets/img/portfolio/agametis_kundenportal_1.png",
        thumbnail: "./assets/img/portfolio/agametis_kundenportal_1.png",
        description: "Aktueller Auftragsstatus",
      },
      {
        original: "./assets/img/portfolio/agametis_kundenportal_2.png",
        thumbnail: "./assets/img/portfolio/agametis_kundenportal_2.png",
        description: "Übersicht des gesamten Kundenportfolios",
      },
    ],
  },
  {
    title: "Betreuung verschiedener Projekte",
    year: "2010 bis heute",
    img: "./assets/img/agametis_logo_modern_sahdow.png",
    desctiption:
      "Unterstützung, Beratung und Weiterentwicklung von fremden FileMaker Lösungen",
  },
];

const contactConfig = {
  adresse_1: "Hermann-Löns-Str. 41",
  adresse_2: "82216 Maisach",
  UST: "DE270038379",
  IMPRESSUM_URL: "www.agametis.de/impressum",
  EMAIL: "mail.hallo@agametis.de",
  TEL_NUMBER: "+49 8142 655 5015",
  EMAIL_TOOLTIP: "kontaktaufnahme",
  BTN_TEXT_EMAIL: "E-Mail-Adresse einblenden",
  BTN_TEXT_TEL: "Telefonnummer einblenden",
  description:
    "Kontaktieren Sie mich einfach direkt per E-Mail oder telefonisch.",
  description_2:
    "Ich bin Adam Augustin, ein zertifizierter FileMaker Entwickler.",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_id",
  YOUR_TEMPLATE_ID: "template_id",
  YOUR_USER_ID: "user_id",
};

const socialprofils = {
  github: "https://github.com/agametis",
  github_aria_label: "Meine Repos auf Github",
  twitter_OFF: "https://twitter.com/agametis",
  twitter_aria_label_OFF: "Lass uns twittern",
  xing: "https://www.xing.com/profile/Adam_Augustin/cv",
  xing_aria_label: "Noch mehr zu meiner Person",
  linkedin: "https://www.linkedin.com/login",
  linkedin_aria_label: "Noch mehr zu meiner Person",
};

const RECAPTCHA_KEY_SITE = "6LfkFK4UAAAAAA6Mk6cSgOvXxjkEKPibf-mxFdbk";

export {
  RECAPTCHA_KEY_SITE,
  contactConfig,
  dataUebrigens,
  dataabout,
  dataerfahrung,
  dataphilosophie,
  dataportfolio,
  introdata,
  logotext,
  meta,
  services,
  socialprofils,
};

