import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Link} from "react-router-dom";
import {dataportfolio, meta} from "../../content_option";
import "./style.css";

import {Portfoliogallery} from "../../components/portfoliogallery";
import {Portfoliomodal} from "../../components/portfoliomodal";

export const Portfolio = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [imgData, setImgData] = useState([]);
  const [poTitle, setPoTitle] = useState("");
  const [poLink, setPoLink] = useState("");
  const [showThumbnails, setShowThumbnails] = useState(true);

  const showPortfolioDetails = (p_title, p_img, p_link) => {
    setPoLink(p_link);
    setPoTitle(p_title);
    setImgData(p_img);
    setIsOpen(true);
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    if (windowSize[0] < 1024 || isMobile) {
      setShowThumbnails(false);
    } else {
      setShowThumbnails(true);
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  return (
    <HelmetProvider>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.title} | Portfolio</title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mt-4 mb-4">Portfolio</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <p>
          {meta.portfoliodescription}
          {isMobile ? <> {meta.portfoliodescription_mobile}</> : null}
        </p>

        <div>
          <Portfoliomodal open={isOpen} onClose={() => setIsOpen(false)}>
            <Portfoliogallery
              portfolioName={poTitle}
              images={imgData}
              showThumbnails={showThumbnails}
              link={poLink}
            ></Portfoliogallery>
          </Portfoliomodal>
        </div>
        <div className="mb-5 po_items_ho">
          {dataportfolio.map((data, i) => {
            return (
              <div key={i} className="po_item">
                <div className="po_title">{data.title}</div>
                <img src={data.img} alt="agametis portfolio" />
                <div className="content">
                  <p className="po_description">{data.desctiption}</p>
                  {data?.images ? (
                    <button
                      className="po_more_btn"
                      onClick={() =>
                        showPortfolioDetails(
                          data.title,
                          data.images,
                          data.link,
                        )
                      }
                    >
                      Mehr
                    </button>
                  ) : null}
                </div>
                <div className="po_year">{data.year}</div>
              </div>
            );
          })}
        </div>
        <Row className="mb-5 mt-3">
          <Col lg="12">
            <p className="mb-5">
              Und <Link to="/filemaker-programmierer-muenchen">hier</Link>{" "}
              können Sie noch etwas mehr über mich erfahren.
            </p>
          </Col>
          <Col className="mt-5"></Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
