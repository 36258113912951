import React, {useCallback, useState} from "react";

import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

import Mailto from "react-protected-mailto";

import "./style.css";

export const Kommunikation = ({action, actionValue, type, btnText}) => {
  const {executeRecaptcha} = useGoogleReCaptcha();
  const [token, setToken] = useState("");

  const clickHandler = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        return;
      }

      const result = await executeRecaptcha(action);

      setToken(result);
    } catch (error) {
      console.log("clickHandler", error);
    }
  }, [action, executeRecaptcha]);

  return (
    <>
      {token ? (
        type === "email" ? (
          <Mailto
            email={actionValue}
            headers={{subject: "Kontaktaufnahme"}}
            obfuscatedHref={"kontaktaufnahme"}
            obfuscate={false}
          />
        ) : (
          <Mailto
            tel={actionValue}
            obfuscatedHref={"kontaktaufnahme"}
            obfuscate={false}
          />
        )
      ) : (
        <>
          <button className="my_button" onClick={clickHandler}>
            {btnText}
          </button>
        </>
      )}
    </>
  );
};
