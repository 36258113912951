import React from "react";
import ReactDom from "react-dom";
import {VscClose} from "react-icons/vsc";

import "./style.css";

export const Portfoliomodal = ({open, children, onClose}) => {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div className="aga_overlay" />
      <div className="aga_modal">
        <div className='d-flex flex-row-reverse'>
          <button className="menu__button nav_ac" onClick={onClose}>
            <VscClose />
          </button>
        </div>
        {children}
      </div>
    </>,
    document.getElementById("portal"),
  );
};
