import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Kommunikation} from "../../components/kommunikation";
import {contactConfig, meta} from "../../content_option";
import "./style.css";

export const ContactUs = () => {
  return (
    <HelmetProvider>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.title} | Kontakt</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mt-4 mb-4">Kontakt</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="12" className="m-0">
            <img
              className="p-4 claris_logo"
              src="./assets/img/agametis_ClarisPartner.png"
              alt="Claris Partner"
            ></img>
          </Col>
          <Col lg="8" className="mb-0">
            <h3 className="color_sec py-4">Fragen, Kooperationen?</h3>
            <p>{contactConfig.description}</p>
            <address>
              <strong>E-Mail:</strong>{" "}
              <Kommunikation
                action="email"
                actionValue={contactConfig.EMAIL}
                type="email"
                btnText={contactConfig.BTN_TEXT_EMAIL}
              />
              <p>
                <strong>Telefon:</strong>{" "}
                <Kommunikation
                  action="email"
                  actionValue={contactConfig.TEL_NUMBER}
                  type="tel"
                  btnText={contactConfig.BTN_TEXT_TEL}
                />
              </p>
            </address>
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="12" className="mb-5">
            <img
              className="p-4 claris_logo"
              src="./assets/img/agametis_fm_zertifizierung.png"
              alt="FileMaker Zertifizierungen"
            ></img>
          </Col>
          <Col className="mt-5"></Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
