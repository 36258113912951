import React, { useEffect, useState } from "react";
import {
  WiMoonAltFirstQuarter,
  WiMoonAltThirdQuarter,
} from "react-icons/wi";
import "./style.css";


const Themetoggle = () => {
  const [theme, setTheme] = useState("light");

  const themetoggle = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  useEffect(() => {
    // Beim Start wird das Theme bezogen auf die Einstellungen des Browsers gewaehlt
    const darkModeIsActive = window.matchMedia(
      "(prefers-color-scheme: dark)",
    ).matches;

    const colorScheme = darkModeIsActive ? "dark" : "light";

    document.documentElement.setAttribute("data-theme", colorScheme);
    setTheme(colorScheme);
  }, []);

  useEffect(() => {
    // Beim Umschalten des Themes
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <div className="nav_ac" onClick={themetoggle}>
      <div className="theme_icon">
        {theme === "light" ? (
          <WiMoonAltFirstQuarter />
        ) : (
          <WiMoonAltThirdQuarter />
        )}
      </div>
    </div>
  );
};

export default Themetoggle;
