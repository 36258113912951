import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Link} from "react-router-dom";
import {
  dataUebrigens,
  dataabout,
  dataerfahrung,
  dataphilosophie,
  meta,
  services,
} from "../../content_option";
import "./style.css";

export const About = () => {
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.title} | FileMaker Programmierer München</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mt-4 mb-4">Über mich</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">{dataabout.title}</h3>
            <div>
              <img
                className="p-0 mb-3 adam_augustin_bild"
                src="./assets/img/adam_augustin.jpg"
                alt="Dr. Adam G. Augustin"
              />
            </div>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <div>
              <p>{dataabout.aboutme_1}</p>
              <p>{dataabout.aboutme_2}</p>
              <img
                className="p-4 claris_logo"
                src="./assets/img/agametis_fm_zertifizierung.png"
                alt="FileMaker Zertifizierungen"
              ></img>
            </div>
          </Col>
        </Row>
        {/* <Row className="sec_sp">
          <Col lg="5"></Col>
          <Col lg="7" className="d-flex align-items-center">
            <div>
              <img
                className="p-4 claris_logo"
                src="./assets/img/agametis_fm_zertifizierung.png"
                alt="FileMaker Zertifizierungen"
              ></img>
            </div>
          </Col>
        </Row> */}
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Auszeichnungen</h3>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <div>
              <p>
                2024 wurde ich im Rahmen der FileMaker Konferenz vom{" "}
                <a
                  href="https://filemaker-magazin.de"
                  target="_blank"
                  rel="noreferrer"
                  title="Homepage des FileMaker Magazins"
                >
                  FileMaker Magazin
                </a>{" "}
                des K&K Verlages mit dem FMM Award 2024 als{" "}
                <a
                  href="https://filemaker-magazin.de/neuigkeit/4271"
                  target="_blank"
                  rel="noreferrer"
                  title="Beste Informationsquelle - Integration von FileMaker mit JavaScript-basierten Frameworks"
                >
                  "Beste Informationsquelle - Integration von FileMaker mit
                  JavaScript-basierten Frameworks"
                </a>{" "}
                ausgezeichnet.
              </p>
              <img
                className="p-1 claris_logo"
                src="./assets/img/FFM-Award-2024_Adam-Augustin.jpg"
                alt="FileMaker Zertifizierungen"
              ></img>
            </div>
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">{dataerfahrung.title}</h3>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <div>
              <p>{dataerfahrung.erfahrung_1}</p>
              <p>{dataerfahrung.erfahrung_2}</p>
              <p>
                Durch meine kontinuierliche Weiterbildung (siehe{" "}
                <Link to="/aktivitaeten"> meine Aktivitäten</Link>) haben Sie
                einen erfahrenen Partner an Ihrer Seite, der stehts aktuelle
                Trends in Design und Techink (siehe{" "}
                <Link to="/portfolio">mein Portfolio</Link>) bei der Entwicklung
                berücksichtigt.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lang="5">
            <h3 className="color_sec py-4">Leistungen</h3>
          </Col>
          <Col lg="7">
            {services.map((data, i) => {
              return (
                <div className="service_ pb-1" key={i}>
                  <h5 className="service__title">{data.title}</h5>
                  <p className="service__desc">{data.description}</p>
                </div>
              );
            })}
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">{dataphilosophie.title}</h3>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <div>
              <p>{dataphilosophie.text_1}</p>
              <p>{dataphilosophie.text_2}</p>
              <p>{dataphilosophie.text_3}</p>
              <p>
                Schauen Sie im Bereich <Link to="/portfolio">Portfolio</Link>{" "}
                vorbei, in dem ich zahlreiche Beispiele meiner Arbeit zeige.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">{dataUebrigens.title}</h3>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <div>
              <p>{dataUebrigens.text}</p>
            </div>
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="12" className="mb-5 d-flex justify-content-center">
            <p>
              Wenn Sie einen kompetenten und zuverlässigen Partner für Ihr
              Unternehmen brauchen <Link to="/kontakt">kontaktieren</Link> Sie
              mich einfach.
            </p>
          </Col>
          <Col lg="12" className="mb-5 d-flex justify-content-center">
            <img
              className="p-4 mb-5 claris_logo"
              src="./assets/img/agametis_ClarisPartner.png"
              alt="Claris Partner"
            ></img>
          </Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
