import "bootstrap/dist/css/bootstrap.min.css";
import React, {useEffect} from "react";
import AnimatedCursor from "react-animated-cursor";
import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
} from "react-router-dom";
import Headermain from "../header";
import "./App.css";
import AppRoutes from "./routes";

function _ScrollToTop(props) {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

export default function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div className="cursor__dot">
        <AnimatedCursor
          innerSize={13}
          outerSize={15}
          color="190, 190 ,190"
          outerAlpha={0.3}
          innerScale={0.8}
          outerScale={3}
        />
      </div>
      <ScrollToTop>
        <Headermain />
        <AppRoutes />
      </ScrollToTop>
    </Router>
  );
}
