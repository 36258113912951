import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import './index.css';

import { RECAPTCHA_KEY_SITE} from "./content_option";

import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={RECAPTCHA_KEY_SITE}
    scriptProps={{async: true, defer: true, appendTo: "body"}}
  >
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById("root"),
);

