import React from "react";
import {Col, Container, Row} from "react-bootstrap";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import "./style.css";

export const Portfoliogallery = ({
  portfolioName,
  images,
  showThumbnails,
  link,
}) => {
  return (
    <div className="my_portfolio_container pb-4 d-flex justify-content-center">
      <Container className="mx-0 px-3">
        <Row className="mb-1 mt-1">
          <Col lg="8">
            <h4 className="display-6 mt-6 mb-4">{portfolioName}</h4>
          </Col>
        </Row>
        {link === "" ? null : (
          <Row>
            <Col lg="12">
              <p>
                Mehr Details zu dem Projekt erfahren Sie unter:{" "}
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  title="Projektdetails"
                >
                  {link}
                </a>
              </p>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg="12">
            <ImageGallery
              showBullets={!showThumbnails}
              thumbnailPosition="top"
              showThumbnails={showThumbnails}
              showIndex={!showThumbnails}
              lazyLoad={true}
              showFullscreenButton={false}
              showPlayButton={false}
              items={images}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
