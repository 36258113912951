import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";

import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Socialicons} from "../components/socialicons";
import {About} from "../pages/about";
import {Aktivitaeten} from "../pages/aktivitaeten";
import {Datenschutz} from "../pages/datenschutz";
import {Home} from "../pages/home";
import {Impressum} from "../pages/impressum";
import {ContactUs} from "../pages/kontakt";
import {Portfolio} from "../pages/portfolio";

const AnimatedSwitch = withRouter(({location}) => (
  <TransitionGroup>
    <CSSTransition
      key={location.key}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="page"
      unmountOnExit
    >
      <Switch location={location}>
        <Route exact path="/" component={Home} />
        <Route path="/filemaker-programmierer-muenchen" component={About} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/kontakt" component={ContactUs} />
        <Route path="/impressum" component={Impressum} />
        <Route path="/datenschutz" component={Datenschutz} />
        <Route path="/aktivitaeten" component={Aktivitaeten} />
        <Route path="*" component={Home} />
      </Switch>
    </CSSTransition>
  </TransitionGroup>
));

function AppRoutes() {
  return (
    <div className="s_c">
      <AnimatedSwitch />
      <Socialicons />
    </div>
  );
}

export default AppRoutes;
